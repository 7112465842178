import { Actions, initActions } from './actions'

import create from 'zustand'
import produce from 'immer'

export type View =
  | 'instagram'
  | 'upload-options'
  | 'verification-code'
  | 'edit-posts'
  // | 'auth-selection'
  | 'new-member'
  // | 'existing-member'
  | 'facebook-login'
  | 'uploading'
  | 'failed-upload'
  | 'thanks-for-sharing'
  | 'remove-auth-options'
  | 'remove-email'
  | 'remove-posts'
  | 'remove-loading'
  | 'remove-final'
  | 'verify-token'

export type ContentSource = undefined | 'instagram' | 'upload' | 'verify'

type IStore = Actions & {
  mode: string
  origin: boolean
  customErrorMessage: string
  shouldClose: boolean
  contentSource: ContentSource
  view: View
  discard: boolean
  error: string
  retry: number
  source: any
  failed: Array<string>
  company: undefined | any
  incentive: undefined | any
  posts: Array<any>
  postIndex: number
  instagramPosts: Array<any>
  email: string
  pendingUploadId: string
  translations: { [key: string]: string }
  name: string
  username: string
  isInstagram: boolean
  gridPosts: Array<any>
  authType: 'new-member' | 'existing-member'
  uploadTypes: {
    directUploads: boolean
    instagramUploads: boolean
  }
  instagramNext: boolean
  progress: {
    total: number
    count: number
  }
  memberPosts: any[]
  set: (fn: (state: IStore) => void) => void
}

const store = (set, get): IStore => ({
  mode: 'page', // modal or page
  origin: false,
  shouldClose: false,
  contentSource: undefined,
  view: 'upload-options',
  discard: false,
  error: undefined,
  retry: 0,
  failed: [],
  source: undefined,
  company: undefined,
  incentive: undefined,
  posts: [],
  postIndex: 0,
  instagramPosts: [],
  isInstagram: false,
  email: '',
  translations: {},
  name: '',
  username: '',
  gridPosts: [],
  instagramNext: false,
  progress: {
    total: 0,
    count: 0
  },
  pendingUploadId: '',
  customErrorMessage: '',
  memberPosts: [],
  authType: undefined,
  uploadTypes: { directUploads: true, instagramUploads: true },
  ...initActions(set, get),
  set: (fn) => set(produce(fn))
})

const useStore = create<IStore>(store)

export { useStore }
